<template>
	<div class="mod-role">
		<el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
			<el-form-item>
				<el-input v-model="dataForm.name" placeholder="角色名称" clearable></el-input>
			</el-form-item>
			<el-form-item>
				<el-button @click="getDataList()">查询</el-button>
				<el-button v-if="isAuth('sys:role:save')" type="primary" @click="addOrUpdateHandle()">新增</el-button>
			</el-form-item>
		</el-form>
		<div class="hint">
			<div>已选中<span class="c-warning">{{dataListSelections.length}}</span>条</div>
			<div>
				<el-button size="mini" type="danger" icon="el-icon-delete" v-if="isAuth('sys:role:delete')" :disabled="dataListSelections.length<=0"
					@click="deleteHandle()">删除</el-button>
			</div>
		</div>
		<el-table :data="dataList" border v-loading="dataListLoading" @selection-change="selectionChangeHandle"
			style="width: 100%;">
			<el-table-column type="selection" header-align="center" align="center" width="50">
			</el-table-column>
			<!-- <el-table-column prop="id" header-align="center" align="center" width="200" label="ID">
			</el-table-column> -->
			<el-table-column prop="username" header-align="center" align="center" label="序号" width="80">
				<template slot-scope="scope">
					 {{(pageIndex - 1) * pageSize + scope.$index + 1}}
				</template>
			</el-table-column>
			<el-table-column prop="name" header-align="center" align="center" label="角色名称">
			</el-table-column>
			<el-table-column prop="remark" header-align="center" align="center" label="备注">
			</el-table-column>
			<el-table-column prop="createDate" header-align="center" align="center" width="180" label="创建时间">
			</el-table-column>
			<el-table-column fixed="right" header-align="center" align="center" width="150" label="操作">
				<template slot-scope="scope">
					<el-button v-if="isAuth('sys:role:update')" type="text" size="small"
						@click="addOrUpdateHandle(scope.row.id)">修改</el-button>
					<el-button v-if="isAuth('sys:role:delete')" class="c-danger-imp" type="text" size="small"
						@click="deleteHandle(scope.row.id)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageIndex"
			:page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalPage"
			layout="total, sizes, prev, pager, next, jumper">
		</el-pagination>
		<!-- 弹窗, 新增 / 修改 -->
		<add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
	</div>
</template>

<script>
	import AddOrUpdate from './role-add-or-update'
	export default {
		data() {
			return {
				dataForm: {
					name: ''
				},
				dataList: [],
				pageIndex: 1,
				pageSize: 10,
				totalPage: 0,
				dataListLoading: false,
				dataListSelections: [],
				addOrUpdateVisible: false
			}
		},
		components: {
			AddOrUpdate
		},
		created() {
			this.getDataList()
		},
		methods: {
			// 获取数据列表
			async getDataList() {
				this.dataListLoading = true
				let param = {
					'page': this.pageIndex,
					'limit': this.pageSize,
					'name': this.dataForm.name
				}
				let res = await this.$get('/platform-admin/role/page', param);
				if (res && res.code == 0) {
					this.dataList = res.data.list
					this.totalPage = res.data.total
				}
				this.dataListLoading = false
			},
			// 每页数
			sizeChangeHandle(val) {
				this.pageSize = val
				this.pageIndex = 1
				this.getDataList()
			},
			// 当前页
			currentChangeHandle(val) {
				this.pageIndex = val
				this.getDataList()
			},
			// 多选
			selectionChangeHandle(val) {
				this.dataListSelections = val
			},
			// 新增 / 修改
			addOrUpdateHandle(id) {
				this.addOrUpdateVisible = true
				this.$nextTick(() => {
					this.$refs.addOrUpdate.init(id)
				})
			},
			// 删除
			deleteHandle(id) {
				var ids = id ? [id] : this.dataListSelections.map(item => {
					return item.id
				})
				this.$confirm(`确定执行删除操作?`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					this.dataListLoading=true;
					let res = await this.$del('/platform-admin/role', ids);
					this.dataListLoading=false;
					if (res && res.code == 0) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					}
				}).catch(() => {})
			}
		}
	}
</script>
<style lang="scss" scoped>
	.hint {
		height: 40px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: #FFF4E3;
		border-radius: 5px;
		padding: 0 17px;
		margin-bottom: 15px;
	}
</style>
